
.search-form-container.legacy-form {
    top: 20% !important;

    .search-form-col {
        > div {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            pointer-events: all;

            .search-form {
                padding: 20px 30px;
                width: 792px;

                .fields-wrapper.fields-wrapper-primary {
                    flex-flow: row wrap;
                    height: 115px;
                    margin: 15px 0;

                    .search-field {
                        flex: 1 1 30%;
                        margin-bottom: 15px;
                        border-radius: 3px;
                        height: 50px;
                        line-height: 50px;

                        &:not(:last-of-type) {
                            border-right: 0;
                        }

                        &.range-search {
                            padding-left: 0;

                            .range {
                                &:first-of-type {
                                    border-right: 1px solid #ccc;
                                    padding-left: 12px;
                                }

                                &:last-of-type {
                                    padding-left: 10px;
                                    margin-right: -10px;
                                }
                            }
                        }

                        &.text-search input {
                            padding-top: 7px;

                            &:placeholder-shown + label {
                                top: 18px;
                            }

                            &:focus + label {
                                top: 10px;
                            }
                        }
                    }

                    .dropdown-main .dropdown-box {
                        top: 50px;
                        border: 1px solid #ccc;
                    }
                }

                >.reset-search-label {
                    color: white;
                }
            }

            .search-form-submit {
                min-width: 250px;
                border-radius: 50px;
                font-size: 16px;
                padding: 15px 20px;
                margin: 30px 0 15px;
            }

            .sub-heading {
                color: white;
            }

            .form-top {
                display: flex;
                align-items: center;

                .heading {
                    margin-right: 30px;
                }
                .heading-inside{
                    margin: auto;
                    color: white;
                    margin-bottom: 5px;
                    font-weight: 400;
                }
                .hideTitle{
                    display: none;
                }
            }

            .silotype-field {
                display: flex;
                position: relative;
                color: white;

                .radio {
                    font-size: 16px;
                    border-bottom: 0;
                    padding: 5px;
                    margin: 0 10px 5px 0;
                    width: 60px;
                    cursor: pointer;
                    text-align: center;
                    z-index: 5;
                }

                .selected-option {
                    position: absolute;
                    display: inline-block;
                    left: 0;
                    top: 0;
                    width: 60px;
                    height: 32px;
                    border-radius: 50px;
                    transition: .3s ease-in-out;
                    z-index: 0;
                }
            }
        }
    }
}
