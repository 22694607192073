
$carsales-primary: #007CC2;
$colour-light-grey: #aaa;

$font-size: 14px;
$primary-field-height: 57px;
$secondary-field-height: 15px;
$primary-field-padding: 12px;
$secondary-row-spacing: 20px;

$thin-border: 1px solid #ddd;
$chevron-width: 11px;

$simple-transition: 250ms ease;

$form-background-color: rgba(0, 0, 0, 0.4);
$form-border-color: #4a4a4a;

.search-form-container {
    background-color: initial !important;

    .heading {
        margin-bottom: 15px;
        color: #fff;
        font-weight: 400;
        pointer-events: all;
    }

    .fadein {
        animation: fadein 1s;
    }

    .search-form-col {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        .search-form {
            padding: 10px 20px;
            margin: auto;
            border-radius: 12px;
            border: 1px solid $form-border-color;
            width: 100%;
            min-height: 124px;
            max-height: 200px;
            background: $form-background-color;
            backdrop-filter: blur(0.5px);
            pointer-events: all;
            z-index: 99;
            transition: height 200ms ease;

            &:hover {
                background-color: $form-background-color;
                border-color: $form-border-color;
            }

            label {
                cursor: pointer;
            }

            .fields-wrapper {
                display: flex;
                flex-flow: row nowrap;

                &-primary {
                    margin: 10px 0;
                    justify-content: space-between;
                    color: #4a4a4a;

                    .search-field {
                        flex: 1 1 0px;
                        height: $primary-field-height;
                        line-height: $primary-field-height;
                        min-width: 0;
                        max-width: 32%;
                        padding: 0 $primary-field-padding;
                        background-color: #fff;
                        font-size: $font-size;

                        &-emphasis {
                            background-color: #eee;
                            font-weight: 600;

                            input {
                                background-color: inherit;
                            }
                        }

                        &.text-search {
                            input {
                                color: #4a4a4a;
                                height: 100%;

                                &::placeholder {
                                    color: #4a4a4a;
                                }
                            }
                        }

                        &.range-search {
                            .range-max {
                                border-left: $thin-border;
                            }
                        }

                        &:not(:last-of-type) {
                            border-right: $thin-border;
                        }

                        &[disabled] {
                            color: $colour-light-grey;

                            & input::placeholder {
                                color: $colour-light-grey;
                            }
                        }
                    }

                    .dropdown-main {
                        .dropdown-value {
                            padding-right: $chevron-width;

                            label {
                                transition: all 0.2s;
                            }

                            span:not(:empty) {
                                position: relative;
                                top: 5px;

                                & + label {
                                    position: absolute;
                                    top: 10px;
                                    line-height: 12px;
                                    font-size: 10px;
                                    left: 0;
                                    color: #888;
                                    transition: all 0.2s;
                                }
                            }
                        }

                        .dropdown-box {
                            top: $primary-field-height;
                            border-top: $thin-border;
                        }

                        &.dropdown-open .dropdown-value {
                            font-weight: 600;
                        }
                    }

                    .arrow-down:after {
                        right: $primary-field-padding;
                        height: $primary-field-height;
                        background: url("./Images/icon-arrow-down-blue.svg") no-repeat center;
                    }

                    > *:first-child {
                        border-radius: 3px 0 0 3px;
                    }

                    > *:last-child {
                        border-radius: 0 3px 3px 0;
                    }
                }

                &-secondary {
                    color: #fff;
                    font-size: $font-size;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    .search-field {
                        margin: 5px 0;

                        &.text-search {
                            height: $secondary-field-height;
                            line-height: $secondary-field-height;

                            input {
                                color: #fff;
                            }
                        }

                        &.dropdown-search {
                            padding-right: 20px;
                        }

                        &.range-search {
                            .range:first-child {
                                margin-right: $secondary-row-spacing;
                            }
                        }

                        &:not(:last-child) {
                            margin-right: $secondary-row-spacing;
                        }
                    }

                    .arrow-down:after {
                        right: 0;
                        height: $secondary-field-height;
                        background: url("./Images/icon-arrow-down-white.svg") no-repeat center;
                    }

                    .dropdown-main {
                        .dropdown-value {
                            height: $secondary-field-height;
                            line-height: $secondary-field-height;

                            span:not(:empty) {
                                & + label {
                                    display: none;
                                }
                            }
                        }

                        .dropdown-box {
                            top: 25px;
                        }
                    }
                }

                .search-field {
                    position: relative;
                    transition: color $simple-transition;

                    &.text-search {
                        display: block;
                        overflow: hidden;
                        white-space: nowrap;
                        cursor: text;

                        input {
                            display: block;
                            background-color: transparent;
                            padding-top: 5px;
                            border: 0;
                            outline: 0;
                            font-style: normal;
                            cursor: inherit;

                            &::placeholder {
                                transition: color $simple-transition;
                            }

                            & + label {
                                position: absolute;
                                line-height: 12px;
                                left: 12px;
                                pointer-events: none;
                                transition: all 0.2s;
                            }

                            &:placeholder-shown + label {
                                top: 23px;
                            }

                            &:not(:placeholder-shown) + label, &:focus + label {
                                top: 10px;
                                font-size: 10px;
                                color: #888;
                                transition: all 0.2s;
                            }
                        }
                    }

                    &.dropdown-search {
                        &:not([disabled]) {
                            cursor: pointer;
                        }
                    }

                    &.range-search {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;

                        .range {
                            min-width: 0;
                            padding-right: 20px;
                            flex: 1 0 auto;
                            cursor: pointer;
                        }
                    }

                    &.modal-search {
                        display: flex;
                        cursor: pointer;
                        flex-grow: 1.5;

                        .modal-value-wrap {
                            max-width: 100%;
                            padding-right: 15px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            .modal-value {
                                display: inline;
                            }
                        }
                    }

                    &[disabled] {
                        color: $colour-light-grey;
                        cursor: not-allowed;

                        &:after {
                            filter: grayscale(100%);
                            opacity: .5;
                        }

                        & input::placeholder {
                            color: $colour-light-grey;
                        }
                    }
                }

                .dropdown-main {
                    position: relative;

                    .dropdown-value {
                        white-space: nowrap;
                        overflow: hidden;
                        position: relative;

                        label {
                            cursor: inherit;
                        }

                        span {
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    .dropdown-box {
                        position: absolute;
                        left: 0;
                        width: auto;
                        min-width: 100%;
                        max-width: 300px;
                        max-height: 350px;
                        padding: 10px 0;
                        border-radius: 0 0 6px 6px;
                        box-shadow: 10px 13px 15px -10px rgba(195, 195, 195, 0.5);
                        background: #fff;
                        color: #000;
                        overflow-x: hidden;
                        overflow-y: auto;
                        cursor: default;
                        z-index: 90;
                        user-select: none;

                        .dropdown-item {
                            position: relative;
                            width: 100%;
                            line-height: 20px;
                            padding: 10px 16px;
                            color: #777;
                            font-size: $font-size;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            cursor: pointer;

                            &.hover {
                                background: #edf7f9;
                                color: transparent;

                                &:after {
                                    content: attr(title);
                                    position: absolute;
                                    display: block;
                                    top: 10px;
                                    left: 16px;
                                    width: 100%;
                                    padding-right: 25px;
                                    font-weight: 600;
                                    color: #777;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                            }
                        }

                        .dropdown-label {
                            padding: 10px 16px;
                            color: #777;
                            line-height: normal;
                            font-size: $font-size;
                            font-weight: 600;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .arrow-down:after {
                    content: "";
                    position: absolute;
                    width: $chevron-width;
                    top: 0;
                }

                input {
                    &[disabled] {
                        background: #fff;
                        color: $colour-light-grey;

                        &::placeholder {
                            color: $colour-light-grey;
                        }
                    }

                    &::placeholder {
                        font-style: normal;
                    }
                }
            }

            .search-form-bottom {
                display: flex;
                flex-flow: row wrap;

                .sub-heading {
                    flex-grow: 1;
                    margin: 5px 0;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 400;
                    text-align: right;
                }

                a.other-link { // a is important to get selector priority
                    flex-grow: 1;
                    margin: 5px 0;
                    line-height: 100%;
                    color: #fff;
                    text-align: right;
                    font-size: $font-size;
                    font-weight: 300;

                    &:after {
                        content: "";
                        float: right;
                        background: url(./Images/icon-arrow-right-white.svg) no-repeat center;
                        width: 15px;
                        height: 15px;
                        margin-left: 5px;
                    }
                }
            }
        }

        .search-modal {
            position: fixed;
            display: flex;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.7);
            align-items: center;
            justify-content: center;
            cursor: pointer;
            // modal must cover sitenav
            z-index: 100000000;

            .search-modal-content {
                display: flex;
                width: 100%;
                max-width: 695px;
                padding: 23px 29px 31px;
                border-radius: 3px;
                background-color: #fff;
                flex-direction: column;
                align-items: center;
                cursor: default;

                .modal-title {
                    line-height: 33px;
                    margin-bottom: 24px;
                    color: #2a2a2a;
                    font-size: 24px;
                    font-weight: 600;
                    cursor: initial;
                }

                .modal-items {
                    display: flex;
                    width: 100%;
                    flex-flow: row wrap;
                    justify-content: flex-start;

                    .modal-item {
                        display: flex;
                        min-height: 45px;
                        max-width: 33%;
                        margin-bottom: 15px;
                        flex-flow: row nowrap;
                        align-items: center;
                        justify-content: flex-start;
                        flex: 1 1 33%;
                        cursor: pointer;

                        &.selected .item-name {
                            color: $carsales-primary;
                        }

                        img {
                            width: 45px;
                        }

                        .item-name {
                            margin: 0 12px;
                            color: #2a2a2a;
                            font-size: $font-size;
                            font-weight: 600;
                        }

                        &:hover .item-name {
                            text-decoration: underline;
                        }

                        .item-count {
                            color: #9b9b9b;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

.search-form-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: $primary-field-height;
    border: 0;
    padding: 20px;
    color: #fff;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: $font-size;
    transition: $simple-transition;
    cursor: pointer;

    > span {
        white-space: nowrap;
        text-align: center;
    }

    &:hover {
        text-decoration: none !important;
        color: #fff;
    }

    &:visited {
        color: #fff;
    }

    &[disabled] {
        color: rgba(255, 255, 255, 0.5);
        background-color: #262626;
        cursor: default;
    }
}
