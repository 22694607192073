
.search-form-container .search-form-col .search-form.nls {
    width: 792px;

    .fields-wrapper-primary {
        .search-field.text-search.nls-search {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            line-height: normal;
            min-width: 450px;
            overflow: visible;

            span.icon-search {
                margin-right: 5px;
            }

            span.icon-close {
                padding: 4px;
                font-size: 22px;
                cursor: pointer;
                pointer-events: none;
                opacity: 0;
                transition: opacity 100ms ease;

                &.visible {
                    pointer-events: all;
                    opacity: 1;
                }
            }

            input {
                flex: 1 0 auto;
                padding-top: unset;
                transition: padding-top 200ms;
            }

            label {
                position: absolute;
                left: 33px;
                color: #888;
                font-size: 10px;
                line-height: 12px;
                opacity: 0;
                transition: top, opacity 200ms;
                pointer-events: none;
            }

            &.keyword-mode {
                input {
                    padding-top: 5px;
                }

                label {
                    top: 8px;
                    opacity: 1;
                }
            }

            .nls-options {
                position: absolute;
                top: 100%;
                left: 0;
                margin-top: 8px;
                padding: 20px 0;
                width: 100%;
                background: #fff;
                border-radius: 3px;
                cursor: default;
                box-shadow: 0 0 15px 3px rgba(195, 195, 195, 0.5);
                z-index: 5;

                .option-title {
                    margin: 0 20px 10px;
                    color: #9b9b9b;
                    font-size: 13px;
                }

                .opt {
                    padding: 6px 20px;
                    font-size: 13px;
                    line-height: 13px;
                    cursor: pointer;

                    &.active {
                        background: #f2f2f2;
                    }
                }

                .keyword-prompt {
                    margin: 20px 0 0 0;
                    padding: 0 20px;
                    white-space: pre-wrap;
                    cursor: pointer;
                }

                .no-results {
                    padding: 0 25px;

                    ul {
                        margin: 0;

                        .keyword-prompt {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }

                hr {
                    margin: 10px 20px;
                    border-color: #dcdcdc;
                }
            }
        }
    }
}
